import UAParser from "ua-parser-js";
import { legacyApiFetch } from "../utils/legacyApiFetch";

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/notSupportedBrowser") {
    return undefined;
  }

  const browserSupported = useSessionStorage("browserSupported", 0);

  if (browserSupported.value !== 0) {
    return browserSupported.value === 2
      ? navigateTo("/notSupportedBrowser")
      : undefined;
  }

  if (!isBrowserSupported()) {
    browserSupported.value = 2;
    callApiBrowserNotSupported();
    return navigateTo("/notSupportedBrowser");
  }

  if (process.client) {
    browserSupported.value = 1;
  }

  return undefined;
});

function callApiBrowserNotSupported() {
  if (process.client) {
    try {
      legacyApiFetch(
        "api/browserNotSupported",
        function (errorCode: number | null) {
          if (errorCode) {
            console.error("browserNotSupported API call failed: ", errorCode);
          }
        },
      );
    } catch (error) {
      console.error("browserNotSupported API call failed: ", error);
    }
  } else {
    useApi("/api/browserNotSupported");
  }
}

function isBrowserSupported(): boolean {
  if (!isSupportingBrowserVersion(getUserAgent())) {
    return false;
  }

  if (process.client && !testAllFeatures()) {
    return false;
  }

  return true;
}

function getUserAgent(): string | undefined {
  return useRequestHeaders(["user-agent"])["user-agent"];
}

function isSupportingBrowserVersion(
  userAgentString: string | undefined,
): boolean {
  const supportedBrowsers: SupportedBrowsers = {
    Chrome: 90,
    Firefox: 88,
    Edge: 90,
    Safari: 14,
    Opera: 75,
    "Samsung Internet": 14,
  };

  const browserInfo = getBrowserInfo(userAgentString);
  if (!browserInfo) {
    return false;
  }

  const { name: browserName, version: browserVersion } = browserInfo;

  if (
    supportedBrowsers[browserName as keyof SupportedBrowsers] &&
    parseInt(browserVersion.split(".")[0]) >=
      supportedBrowsers[browserName as keyof SupportedBrowsers]
  ) {
    return true;
  }

  return false;
}

function getBrowserInfo(
  userAgentString: string | undefined,
): { name: string; version: string } | undefined {
  const parser =
    userAgentString !== undefined
      ? new UAParser(userAgentString)
      : new UAParser();
  const result = parser.getResult();

  const browserName = result.browser.name;
  const browserVersion = result.browser.version;

  return browserName && browserVersion
    ? {
        name: browserName,
        version: browserVersion,
      }
    : undefined;
}

type SupportedBrowsers = {
  Chrome: number;
  Firefox: number;
  Edge: number;
  Safari: number;
  Opera: number;
  "Samsung Internet": number;
};
